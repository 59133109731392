<template>
  <div>
    <head-layout
      :head-title="$t('cip.dc.EXCELDComission.title')"
      :head-btn-options="headBtnOptions"
      @head-add="addFn"
    >
      <!-- @head-romve="deleteFn" -->
    </head-layout>

    <grid-layout
      ref="gridLayout"
      :data-total="total"
      @page-size-change="handleSizeChange"
      @page-current-change="handleCurrentChange"
      :page="page"
      @gird-handle-select-click="handleSelectionChange"
      :tableOptions="tableOptions"
      :tableData="tableData"
      :tableLoading="tableLoading"
      :gridRowBtn="gridRowBtn"
      @grid-edit="handleEdit"
      @grid-see="Mliulan"
      :searchColumns="searchColumns"
    >
    </grid-layout>

    <el-drawer :direction="direction" :visible.sync="drawer1" size="60%">
      <div style="padding: 0px 20px">
        <el-table
          :data="liulanList"
          border
          style="width: 100%"
          class="show_table"
        >
          <el-table-column
            v-for="(item, index) in liulanListLabel"
            :key="index"
            :label="item"
            :prop="item"
          >
          </el-table-column>
        </el-table>

        <el-pagination
          @size-change="handleSizeChange2"
          @current-change="handleCurrentChange2"
          :currentPage="currentPage2"
          :page-sizes="[50, 100]"
          :page-size="PageSize2"
          layout="total,sizes, prev, pager, next"
          :total="totalCount2"
          class="paging"
        >
        </el-pagination>
      </div>
    </el-drawer>

    <el-dialog
      :title="$t('cip.dc.DComission.tip')"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <span>{{ dialogtext }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{ $t('cip.dc.DComission.false') }}</el-button>
        <el-button type="primary" @click="SureToExecute"> {{ $t('cip.dc.DComission.sure') }} </el-button>
      </span>
    </el-dialog>

    <el-dialog
    :title="$t('cip.dc.DComission.tip')"
      :visible.sync="dialogExecute"
      width="30%"
      :before-close="handleClose"
    >
      <p>
        {{ dialogtext }}
      </p>

      <el-date-picker
        v-model="ExecuteTime"
        type="datetimerange"
        value-format="yyyy-MM-dd HH:mm:ss"
        :range-separator="$t('cip.dc.DComission.to')"
        :start-placeholder="$t('cip.dc.DComission.startdate')"
        :end-placeholder="$t('cip.dc.DComission.enddate')"
      >
      </el-date-picker>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogExecute = false">{{ $t('cip.dc.DComission.false') }}</el-button>
        <el-button
          type="primary"
          @click="SureToExecute"
          :loading="Executeloading"
          >{{ $t('cip.dc.DComission.sure') }}</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  excelPageList,
  excelmetadataList,
  tokentDel,
  tokenInitcode,
} from "@/api/dataAcquisition/index";
import {
  DelOffline,
  stopOffline,
  zxOffline2,
} from "@/api/dataAcquisition/offline";
import { mapGetters } from "vuex";
import website from "@/config/website";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import formLayout from "@/views/components/layout/form-layout";
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout,
  },
  data() {
    return {
      totalCount2: 0,
      drawer1: false,
      liulanList: [],
      liulanListLabel: [],
      Executeloading: false,
      ExecuteTime: [], //执行时间
      dialogExecute: false,
      dialogtext: "",
      runState: "",
      btnType: "",
      dialogVisible: false,
      gridRowBtn: [
        {
          label: this.$t("cip.cmn.btn.viewBtn"),
          emit: "grid-edit",
          remark: "edit",
          type: "text",
          icon: "",
        },
        {
          label: this.$t("cip.dc.DataDirectory.btn.BrowseData"),
          emit: "grid-see",
          remark: "remove",
          type: "text",
          icon: "",
        },
      ],
      tableOptions: {
        customRowAddBtn: false,
        menuWidth: 200,
        column: [
          {
            label: this.$t('cip.dc.EXCELDComission.yuanname'),
            prop: "excelName",
            cell: true,
            overHidden: true,
          },
          {
            label: this.$t('cip.dc.EXCELDComission.sourceName'),
            prop: "sourceName",
            cell: true,
            overHidden: true,
          },
          {
            label: this.$t('cip.dc.EXCELDComission.tableName'),
            prop: "tableName",
            cell: true,
            overHidden: true,
          },
          {
            label: this.$t('cip.dc.EXCELDComission.createUser'),
            prop: "createUser",
            cell: true,
            overHidden: true,
          },
          {
            label: this.$t('cip.dc.EXCELDComission.createTime'),
            prop: "createTime",
            cell: true,
            overHidden: true,
          },
        ],
      },
      page: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0,
      },
      tableLoading: false,
      multipleSelection: [],
      tableData: [],
      total: 0,
      queryList: {
        pageNum: 1,
        pageSize: PAGE_CONSTANT.TEN,
      },
      PageSize2: 50,
      currentPage2: 1,
      loading: false,
      rowId: "",
      headBtnOptions: [
        {
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add",
          type: "button",
          icon: "",
        },
      ],
    };
  },
  computed: {},
  methods: {
    // 修改每页显示的条数
    handleSizeChange2(side) {
      // 改变每页显示的条数
      this.PageSize2 = side;
      // 注意：在改变每页显示的条数时，要将页码显示到第一页
      this.currentPage2 = 1;

      let parms = {
        id: this.rowId,
        pageNum: this.currentPage2,
        pageSize: side,
      };

      excelmetadataList(parms).then((res) => {
        this.liulanListLabel = [];
        this.liulanList = res.data.data.dataList;
        this.totalCount2 = res.data.data.page.totalSize;
        this.liulanList.forEach((element) => {
          this.liulanListLabel = Object.keys(element);
        });
      });
    },

    // 显示第几页
    handleCurrentChange2(pageNumber) {
      // 改变默认的页数
      this.currentPage2 = pageNumber;
      let parms = {
        id: this.rowId,
        pageNum: this.currentPage2,
        pageSize: this.PageSize2,
      };

      excelmetadataList(parms).then((res) => {
        this.liulanListLabel = [];
        this.liulanList = res.data.data.dataList;
        this.totalCount2 = res.data.data.page.totalSize;
        this.liulanList.forEach((element) => {
          this.liulanListLabel = Object.keys(element);
        });
      });
    },
    Mliulan(e) {
      this.drawer1 = true;
      this.rowId = e.id;
      excelmetadataList({ id: e.id, pageNum: 1, pageSize: 50 }).then((res) => {
        this.liulanListLabel = [];
        this.liulanList = res.data.data.dataList;
        this.totalCount2 = res.data.data.page.totalSize;
        this.liulanList.forEach((element) => {
          this.liulanListLabel = Object.keys(element);
        });
      });
    },
    onLoad() {
      this.loading = true;
      excelPageList(this.queryList).then((res) => {
        this.loading = false;
        this.tableData = res.data.data.dataList;
        this.total = res.data.data.page.totalSize;
      });
    },
    getAutoCodeFn() {
      tokenInitcode().then((res) => {
        console.log(res);
        this.form.infCode = res.data.data;
      });
    },
    addFn() {
      this.$router.push({
        path: "/business/datacenter/dataAcquisition/excelForNavicat",
        query: {
          type: "add",
        },
      });
    },
    handleEdit(item) {
      this.$router.push({
        path: "/business/datacenter/dataAcquisition/excelForNavicat",
        query: {
          type: "edit",
          id: item.id,
        },
      });
    },
    delRow(e) {
      let ids = { id: e.id };

      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          DelOffline(ids).then((res) => {
            this.$message.success(this.$t('cip.dc.offlineDComission.delmsg'));
            this.onLoad();
          });
        })
        .catch(() => {});
    },
    deleteFn() {
      if (this.multipleSelection.length == 0) {
        this.$message.error(this.$t('cip.dc.offlineDComission.deltip'));
        return;
      }
      let ids = this.multipleSelection.map((item) => {
        return item.id;
      });
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          tokentDel(ids).then((res) => {
            this.$message.success(this.$t('cip.dc.offlineDComission.delmsg'));
            this.onLoad();
          });
        })
        .catch(() => {});
    },

    toExecute(item) {
      if (item.runState == 0) {
        this.dialogtext = this.$t('cip.dc.DComission.msg1');
        this.btnType = "execute";
      }
      if (item.runState == 1) {
        this.dialogtext = this.$t('cip.dc.DComission.msg2');
        this.btnType = "stop";
      }
      this.dialogVisible = true;
      this.ExecuteId = item.id;

      this.runState = item.runState;
    },

    toExecute2(item) {
      this.ExecuteTime = "";
      this.dialogtext = this.$t('cip.dc.DComission.runtime');
      this.btnType = "zx";
      this.dialogExecute = true;
      this.ExecuteId = item.id;
      this.runState = item.runState;
    },

    SureToExecute() {
      this.dialogVisible = false;

      if (this.btnType == "zx") {
        if (this.ExecuteTime) {
          this.Executeloading = true;

          zxOffline2({
            id: this.ExecuteId,
            incrStartTime: this.ExecuteTime[0],
            incrEndTime: this.ExecuteTime[1],
          })
            .then((res) => {
              this.$message({
                message: this.$t('cip.dc.DComission.msg3'),
                type: "success",
              });
              this.onLoad();
              this.dialogExecute = false;
              this.Executeloading = false;
            })
            .catch((res) => {
              this.dialogExecute = false;
              this.Executeloading = false;
            });
        } else {
          this.$message({
            message: this.$t('cip.dc.DComission.selectPlaceholder')+this.$t('cip.dc.DComission.runtime'),
            type: "warning",
          });
        }
      }

      if (this.btnType == "execute") {
        stopOffline({ id: this.ExecuteId, runState: "1" }).then((res) => {
          this.onLoad();
          this.$message({
            message: this.$t('cip.dc.DComission.msg4'),
            type: "success",
          });
        });
      }
      if (this.btnType == "stop") {
        stopOffline({ id: this.ExecuteId, runState: "0" }).then((res) => {
          this.onLoad();
          this.$message({
            message:  this.$t('cip.dc.DComission.msg5'),
            type: "success",
          });
        });
      }
    },

    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;
    },

    handleSizeChange(val) {
      this.page.pageSize = val.pageSize;
      this.queryList.pageNum = 1;
      this.queryList.pageSize = val.pageSize;
      this.onLoad();
    },
    handleCurrentChange(val) {
      this.page.currentPage = val.currentPage;
      this.queryList.pageNum = val.currentPage;
      this.onLoad();
    },
  },
  created() {
    this.onLoad();
  },
  mounted() {},
};
</script>
<style scoped>
.headers {
  background: #ffffff;
  padding: 14px 12px;
}
.headers ::v-deep .el-button {
  height: 28px;
}

.el-button + .el-button {
  margin-left: 0px;
}
.paging {
  float: right;
  margin-top: 10px;
}
.show_table {
  position: relative;
  width: 100%;
  height: 88vh;
  overflow: auto;
}
</style>


